import { theme } from "@/theme";
import styled from "styled-components";
import { PlacementCardProps } from "./types";

export const StyledPlacementCard = styled.div<PlacementCardProps>`
  //background: ${theme.gradients.misc[2]};
  background: ${(props) =>
    props.type === "firstJob"
      ? theme.gradients.misc[2]
      : props.type === "salaryHike"
      ? theme.gradients.misc[4]
      : ""};
  padding: 56px 8px 8px 8px;
  border-radius: 16px;
  min-height: 304px;
`;
