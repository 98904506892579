import GridContainer from "@/design-system/GridContainer";
import TestimonialCard from "./TestimonialCard";

const Testimonials = ({ data, pageTheme }: any) => {
  return (
    <GridContainer>
      {data.map((el: any, key: any) => {
        return <TestimonialCard key={key} data={el} pageTheme={pageTheme} />;
      })}
    </GridContainer>
  );
};

export default Testimonials;
