import { motion } from "framer-motion";
import { useLayoutEffect, useRef, useState } from "react";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import ReadMore from "@/design-system/ReadMore/ReadMore";
import Typography from "@/design-system/Typography";
import useMediaQuery from "@/design-system/useMediaQuery";

import { theme } from "@/theme";

const TopHeading = () => {
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const animateText = useRef<HTMLDivElement | null>(null);
  const [animateY, setAnimateY] = useState<number[]>([45, 88]);

  useLayoutEffect(() => {
    isDesktop ? setAnimateY([83, 147]) : setAnimateY([45, 88]);
  }, [isDesktop]);

  return (
    <>
      Invest in your{" "}
      <Box
        as={"span"}
        overflow="hidden"
        display={"inline-block"}
        height={{ _: "43px", md: "63px" }}
        mb={"-7px"}
      >
        <motion.div
          ref={animateText}
          transition={{
            ease: "easeOut",
            duration: 5,
            delay: 1,
            repeat: Infinity,
            times: [0, 0.6, 0.6],
            repeatDelay: 1,
            repeatType: "loop",
          }}
          animate={{ y: [-3, -animateY[0], -animateY[1], 0] }}
        >
          future. <br /> growth. <br /> career.
        </motion.div>
      </Box>
    </>
  );
};

const BIMPageData = {
  courseId: "BIM",
  seo: {
    title: "bim page",
    img: "hero_Image_BIM.jpeg",
    para: "Learn the most in-demand skill of the Architecture, Engineering and Construction (AEC) industry and boost your career growth.",
    name: "BIM Professional Course",
    analyticsName: "Building Information Modelling",
  },
  heroSection: {
    topHeading: <TopHeading />,
    heading: (
      <span>
        BIM Professional <br /> Course for Architects
      </span>
    ),
    title: "Become a BIM expert with our RIBA-standard and Autodesk-certified course.",
  },
  courseHighlights: {
    sliderText: [
      "RIBA structured international project",
      "60% average CTC hike",
      "Dedicated mentors support",
      "Assured placement assistance",
    ],
    sectionHeading: {
      title: "Course Highlights",
      heading: (
        <span>
          Learn <span style={{ color: theme.colors.primary["700"] }}>Revit</span> and{" "}
          <span style={{ color: theme.colors.primary["700"] }}>7+ BIM</span> software with industry
          workflows <span style={{ color: theme.colors.primary["700"] }}>in just 6 months.</span>
        </span>
      ),
    },
    featureCardList: [
      {
        icon: (
          <Icon
            color={theme.colors.purple[700]}
            type={"Scaled - N - Arrow - Top Right"}
            varient={IconVarient.scaled}
            size={56}
          />
        ),
        heading: (
          <span>
            60% Average <br /> CTC Hike
          </span>
        ),
        description: (
          <span>Get placement assistance to land BIM jobs in globally operating AEC firms.</span>
        ),
        // link: "https://www.novatr.com/blog/bim-graduate-placements",
      },
      {
        icon: (
          <Icon
            color={theme.colors.purple[700]}
            type={"Scaled - N - RIBA"}
            varient={IconVarient.scaled}
            size={56}
          />
        ),
        heading: <span>International RIBA Project Experience</span>,
        description: (
          <span>
            Practise your skills by working collaboratively on a real-world, RIBA-structured
            project.
          </span>
        ),
        // link: "https://www.archdaily.com/984231/how-gamification-can-transform-architecture-education",
      },
      {
        icon: (
          <Icon
            color={theme.colors.purple[700]}
            type={"Scaled - N - Message"}
            varient={IconVarient.scaled}
            size={56}
          />
        ),
        heading: <span>Guaranteed Career Guidance</span>,
        description: (
          <span>
            Prepare for success with guided interviews, portfolio building, and LinkedIn
            optimization.
          </span>
        ),
      },
      {
        icon: (
          <Icon
            color={theme.colors.purple[700]}
            type={"Scaled - N - Input"}
            varient={IconVarient.scaled}
            size={56}
          />
        ),
        heading: <span>Dedicated Mentor Support</span>,
        description: (
          <span>
            Stay on track throughout with quick query resolution and personalised feedback.
          </span>
        ),
      },
    ],
  },
  courseSection: {
    courseOverviewSection: {
      sectionHeading: {
        title: "Course Overview",
        heading: (
          <span>
            Become a <span style={{ color: theme.colors.purple["700"] }}>BIM expert</span> and
            accelerate your <span style={{ color: theme.colors.purple["700"] }}>career</span> in the
            AEC industry.
          </span>
        ),
      },
      courseOverviewCard: {
        heroImages: [
          {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_course_overview_1.png`,
            alt: "Become a BIM Expert at Novatr",
          },
          {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_course_overview_2.png`,
            alt: "Accelerate your Career in AEC with Novatr",
          },
        ],
        para: (
          <>
            <Typography varient={"bodyl"} weightVarient={"regular"}>
              With digitalization accelerating, Building Information Modelling is well on its way to
              becoming a non-negotiable, prerequisite skill in the global construction industry.
              This 6-month course is designed to help you launch into a successful BIM career, no
              matter how little experience or technical knowledge you currently have. Our flexible
              curriculum features an engaging mix of weekly live sessions, mentor interactions, and
              a capstone project.
            </Typography>
            <Typography
              varient={"heading5"}
              weightVarient={"semibold"}
              color={theme.colors.purple["900"]}
              style={{ marginTop: "32px" }}
            >
              You will learn alongside a vibrant, international community of architects to become
              proficient in all essential BIM tools and workflows.
            </Typography>
            <ReadMore>
              <Typography
                style={{ paddingTop: "32px" }}
                varient={"bodyl"}
                weightVarient={"regular"}
              >
                Tailored to fit your busy life, this course lets you learn flexibly. By the end of 6
                months, you will have gained a BIM certification, a professional-level portfolio,
                and access to new job opportunities.
              </Typography>
            </ReadMore>
          </>
        ),
      },
    },
    aboutCourse: {
      sectionHeading: {
        title: "About BIM",
        heading: (
          <span>
            Who is a <span style={{ color: theme.colors.purple["700"] }}>BIM professional</span>?
          </span>
        ),
      },
      archEngineerCard: {
        para: (
          <>
            <Typography varient={"bodyl"} weightVarient={"regular"}>
              BIM professionals include architects, engineers, and construction professionals who
              actively apply Building Information Modelling – a highly collaborative process that
              allows you to plan, design, and analyse a built environment within a 3D model
              throughout its lifecycle.
            </Typography>
            <ReadMore>
              <Typography varient={"bodyl"} weightVarient={"regular"} marginTop="24px">
                BIM professionals build connected, interoperable workflows, and efficient systems
                for analysis, collaboration and visualisation. Amid the digital transformation, they
                form the backbone of today's AEC industry.
              </Typography>
            </ReadMore>
            <Typography varient={"heading3"} weightVarient={"semibold"} marginTop="60px" as={"h2"}>
              Why learn <span style={{ color: theme.colors.purple["700"] }}>BIM</span>?
            </Typography>
            <Typography marginTop="16px" varient={"bodyl"} weightVarient={"regular"}>
              BIM is booming! This hot field is full of exciting opportunities for architects <br />
              and engineers seeking intellectually and financially rewarding careers.
            </Typography>
          </>
        ),
        leftSection: {
          image: {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_adoption.png`,
            alt: "BIM Adoption Rate",
          },
          icon: `Scaled - N - Zoom`,
          heading: "Career opportunities",
          para: "By 2024, 89% of architecture firms, 72% of civil engineering firms, and 69% of construction firms worldwide would be actively using BIM.",
        },
        rightSection: {
          image: {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_high_pay.png`,
            alt: "BIM Professionals earn comparatively more",
          },
          icon: `Scaled - N - Outwards`,
          heading: "Higher Pay",
          para: "A BIM skilled professional is paid 40% more on average, compared to other professionals with similar experience.",
        },
      },
    },
    howYouLearn: {
      sectionHeading: {
        title: "How you Learn",
        heading: (
          <span>
            Get ahead <span style={{ color: theme.colors.purple["700"] }}>by doing</span>
          </span>
        ),
      },
      statesData: {
        state1: {
          stateIdx: 0,
          wire: {
            stokeColor: "#07B765",
          },
          pointer: {
            pointerLabel: "1",
            pointerColor: "#07B765",
          },
          centerImage: {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_learn.png`,
            alt: "Learn at Novatr",
          },
          labels: [
            { label: "Revise Content", left: 70 },
            { label: "Live Classes", left: 60 },
            { label: "Query Resolution", left: 80 },
          ],
        },
        state2: {
          stateIdx: 1,
          wire: {
            stokeColor: "#578D82",
          },
          pointer: {
            pointerLabel: "2",
            pointerColor: "#578D82",
          },
          centerImage: {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_practice.png`,
            alt: "Practice with Novatr",
          },
          labels: [
            { label: "Feedback", left: 40 },
            { label: "Assignments", left: 55 },
            { label: "Live Projects", left: 65 },
          ],
        },
        state3: {
          stateIdx: 2,
          wire: {
            stokeColor: "#6F6C62",
          },
          pointer: {
            pointerLabel: "3",
            pointerColor: "#6F6C62",
          },
          centerImage: {
            src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_achieve.png`,
            alt: "Achieve at Novatr",
          },
          labels: [
            { label: "Porfolio", left: 45 },
            { label: "Placements", left: 50 },
            { label: "Hiring Network", left: 70 },
          ],
        },
      },
      slidesData: [
        {
          heading: "1. Learn",
          imageSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_learn.png`,
          imageAlt: "Learn at Novatr",
          badgesText: ["Revise Content", "Live Classes", "Query Resolution"],
          pointers: [
            "Attend weekly live sessions with expert mentors.",
            "Reinforce your learnings with assignments and activities.",
            "Get quick query resolution and personalised feedback.",
          ],
          state: "state1",
        },
        {
          heading: "2. Practice",
          imageSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_practice.png`,
          imageAlt: "Practice with Novatr",
          badgesText: ["Assignment", "Live Projects", "Feedback"],
          pointers: [
            "Perform collaborative tasks guided by subject experts.",
            "Work on a live, RIBA-structured capstone project.",
            "Map your skill development with detailed reports and feedback.",
          ],
          state: "state2",
        },
        {
          heading: "3. Achieve",
          imageSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/bim_achieve.png`,
          imageAlt: "Achieve at Novatr",
          badgesText: ["Portfolio", "Placements", "Hiring Network"],
          pointers: [
            "Prepare yourself for placements once you graduate.",
            "Sit for mock interviews and optimise your portfolio and Linkedin.",
            "Connect with our network of hiring partners for job opportunities",
          ],
          state: "state3",
        },
      ],
    },
    curriculumSection: {
      sectionHeading: {
        title: "Curriculum",
        heading: (
          <span>
            A{" "}
            <span style={{ color: theme.colors.purple["700"] }}>
              carefully crafted learning journey
            </span>{" "}
            that takes your career exactly where you envisioned it.
          </span>
        ),
      },
      couseCurriculum: [
        {
          srNo: "00.",
          header: "Introduction to BIM: Overview",
          description: [
            {
              topText: "WEEK 00-01",
              header: "Introduction to BIM - Overview",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Before starting your upskilling journey, get acquainted with the need for BIM enablement in the AEC industry. Understand the transition from tradition life cycle to BIM lifecycle.",
            },
          ],
          leftChip: "1 Week",
          rightChip: "0 Activities",
        },
        {
          srNo: "01.",
          header: "Basics of BIM Modelling",
          description: [
            {
              topText: "WEEK 02-04",
              header: "Basics of BIM Modelling",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Learn the fundamentals of modelling in BIM environment and dive head-first into building your spine project from scratch. This modules enables you to set up your BIM model, create and edit elements of your model, master families, build massing and much more.",
            },
          ],
          leftChip: "3 Weeks",
          rightChip: "29 Activities",
        },
        {
          srNo: "02.",
          header: "Advanced BIM Modelling",
          description: [
            {
              topText: "WEEK 05-06",
              header: "Architecture",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "With fundamentals out of the way, it's time to study the architectural process of building project model - building site, topography, creating parametric components and doing design analysis. ",
            },
            // {
            //   topText: "WEEK 05-06",
            //   header: "Civil Engineering",
            //   orAfterThis: false,
            //   lineAfterThis: false,
            //   description:
            //     "With fundamentals out of the way, it's time to equip yourself with the tools used to model and detail structural elements- creating and editing building sub-structure and super-structure,  creating walls, columns, beams, floor, foundation and more.",
            // },
          ],
          leftChip: "1.5 Weeks",
          rightChip: "12 Activities",
        },
        {
          srNo: "03.",
          header: "Information Management",
          description: [
            {
              topText: "WEEK 07-08",
              header: "Presentation and Documentation for Information Management",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Practically apply your project- and process-related learnings to a complex BIM model. Understand when to stop modelling and start detailing your Model. Know how data is optimized and organized to in order to present it to other stakeholders.",
            },
          ],
          leftChip: "2 Weeks",
          rightChip: "14 Activities",
        },
        {
          srNo: "04.",
          header: "3D Visualisation",
          description: [
            {
              topText: "WEEK 09-10",
              header: "3D Visualisation (Architecture)",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Explore  the possibilities of virtual reality (VR) and real-time rendering for making impactful client presentations.",
            },
            // {
            //   topText: "WEEK 09-10",
            //   header: "Analytical Modelling (Structures)",
            //   orAfterThis: false,
            //   lineAfterThis: false,
            //   description:
            //     "Understand structural analysis and its uses. Explore the basics of analysis structural models with the help of ETABS Learn modeling tools, analysis methods and solution techniques for simple structural frames.",
            // },
          ],
          leftChip: "1.5 Weeks",
          rightChip: "15 Activities",
        },
        {
          srNo: "05.",
          header: "BIM Processes and Industry Workflows",
          description: [
            {
              topText: "WEEK 10-11",
              header: "BIM Processes and Industry Workflows",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Understand the workflows in a BIM Project along with the strategies and processes used globally. Learn how to efficiently collaborate, exchange and integrate data with various professionals on one central BIM model.",
            },
          ],
          leftChip: "2 Weeks",
          rightChip: "0 Activities",
        },
        {
          srNo: "06.",
          header: "Team Collaboration and Multidisciplinary Workflow",
          description: [
            {
              topText: "WEEK 12-13",
              header: "Team Collaboration and Mutlidisciplinary Workflow.",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Explore how cloud collaboration works within a team and with other consultants. Learn how to use various platforms for effective collaboration in the BIM environment which combines 3D geometries from various disciplines, for efficient clash detection that helps reduce the risk of errors on site.",
            },
          ],
          leftChip: "2 Weeks",
          rightChip: "13 Activities",
        },
        {
          srNo: "07.",
          header: "BIM Coordination using Navisworks",
          description: [
            {
              topText: "WEEK 14",
              header: "BIM Coordination",
              orAfterThis: false,
              lineAfterThis: false,
              description: "Learn how to use Navisworks in 4D and 5D phases of  BIM Coordination.",
            },
          ],
          leftChip: "1 Week",
          rightChip: "7 Activities",
        },
        {
          srNo: "M08.",
          header: "Elective",
          description: [
            {
              topText: "WEEK 15-16",
              header: "Dynamo",
              orAfterThis: true,
              lineAfterThis: false,
              description:
                "This elective is ideal for those interested in visual programing. Dynamo and Revit together can be utilized to model and analyze complex geometries, automate repetitive processes, minimize human error, and export data to Excel files and other file-types not typically supported by Revit.",
            },
            {
              topText: "WEEK 15-16",
              header: "Sustainability Tools",
              orAfterThis: true,
              lineAfterThis: false,
              description:
                "Ideal for candidates interested in energy-efficient designs and conducting performance analysis for BIM models. Learn how Energy Modelling can allow you to carry out Site Analysis , Automated Energy Modelling and 3D Visualization for Daylight, Glare, Shadow, Radiation etc.",
            },
            {
              topText: "WEEK 15-16",
              header: "Primavera",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Learn planning, managing and executing your BIM project work using Primavera.",
            },
            // {
            //   topText: "WEEK 15-16",
            //   header: "Introduction To Civil 3D",
            //   orAfterThis: false,
            //   lineAfterThis: false,
            //   description:
            //     "Learn to create workflows for civil project using this tool that enables you to build for large scale projects like roads, dams, tunnels, etc.",
            // },
          ],
          leftChip: "2 Weeks",
          rightChip: "4 Activities",
        },
        {
          srNo: "M09.",
          header: "Career Paths in BIM and RIBA - Plan of work",
          description: [
            {
              topText: "WEEK 17",
              header: "Career Paths in BIM and RIBA - Plan of work",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Go through all stages of a project wrt BIM and get a wordview of the career paths that exist within the industry. Learn everything you need to learn to run a project in your career ahead.",
            },
          ],
          leftChip: "1 Week",
          rightChip: "0 Activities",
        },
        {
          srNo: "",
          header: "Capstone Project",
          backgroundColor: theme.colors.primary[50],
          description: [
            {
              topText: "WEEK 18-24",
              header: "Capstone Project",
              orAfterThis: false,
              lineAfterThis: false,
              description:
                "Work on a live capstone project to apply everything you've learnt. This stage also focusses on building your resume, preparing your portfolio with the project done.",
            },
          ],
          leftChip: "8 Weeks",
          rightChip: "0 Activities",
        },
      ],
    },
    mentorPanel: {
      sectionHeading: {
        heading: (
          <span>
            <span style={{ color: theme.colors.purple[600] }}>Mentor</span> Panel
          </span>
        ),
        title: "Meet your mentors",
      },
      mentors: [
        {
          name: "Murali Manoj",
          designation: "Senior Computational Engineer",
          location: "Ramboll",
          description:
            "A Computational Engineer expert in structural analysis, Automation, BIM and an interest towards management",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/murali.png`,
          icon: "/icons/misc/ramboll.svg",
          iconWhite: "/icons/mentor/ramboll.svg",
          iconSize: {
            width: "120",
            height: "40",
          },
        },
        {
          name: "Afshan Rehman",
          designation: "Sustainability Project Manager at TLP",
          location: "The Levy Partnership",
          description:
            "A sustainable consultant  with an ardor for creating a cleaner and greener world through policies, and building construction.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/rehman.png`,
          icon: "/icons/misc/levy.svg",
          iconWhite: "/icons/mentor/levy.svg",
          iconSize: {
            width: "140",
            height: "40",
          },
        },
        // {
        //   name: "Prasad Deosarkar",
        //   designation: "BIM Coordinator",
        //   location: "Jacobs International Consultants Pte. Ltd.",
        //   description:
        //     "Experienced BIM Consultant and an expert in the infrastructural aspect of BIM and construction management.",
        //   src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/prasad.png`,
        //   icon: "/icons/misc/jacobs.svg",
        //   iconWhite: "/icons/mentor/jacobs.svg",
        //   iconSize: {
        //     width: "120",
        //     height: "40",
        //   },
        // },
        {
          name: "Richard Stewart",
          designation: "Senior BIM Manager",
          location: "B+H ARCHITECTS",
          description:
            "Leads the application of BIM/Digital Engineering knowledge at each stage of a construction process.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/richard.png`,
          icon: "/icons/misc/bh.svg",
          iconWhite: "/icons/mentor/bh.svg",
          iconSize: {
            width: "80",
            height: "40",
          },
        },
        {
          name: "Giuseppe Dotto",
          designation: "BIM Coordinator",
          location: "UNStudio, Amsterdam, NL",
          description:
            "Specialized in digital design technology - computational design, programming language and software development.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/gluseppe.png`,
          icon: "/icons/misc/uns.svg",
          iconWhite: "/icons/mentor/uns.svg",
          iconSize: {
            width: "90",
            height: "40",
          },
        },
        {
          name: "Ami Nigam",
          designation: "Head of Technology at Benoy",
          location: "Benoy",
          description:
            "Architect & Design Technology Strategist currently working as the Head of Technology at Benoy.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/ami.png`,
          icon: "/icons/misc/benoy.svg",
          iconWhite: "/icons/mentor/benoy.svg",
          iconSize: {
            width: "100",
            height: "40",
          },
        },
        {
          name: "Harsha Vinjam",
          designation: "Architectural Designer | LEED Green Associate",
          location: "Cooper Carry",
          description:
            "Experienced Architectural Designer with specialisation in Sustainability and BIM.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/harsha.png`,
          icon: "/icons/misc/cooper.svg",
          iconWhite: "/icons/mentor/cooper.svg",
          iconSize: {
            width: "126",
            height: "63",
          },
        },
        {
          name: "André Malheiro",
          designation: "Founder & Architect at AM-arqstudio | BIM Specialist",
          location: "AM-arqstudio",
          description:
            "Founded AM-arqstudio, believing in advanced technology can help create positive impacts on the environment.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/andre.png`,
          icon: "/icons/misc/am.svg",
          iconWhite: "/icons/mentor/am.svg",
          iconSize: {
            width: "71",
            height: "28",
          },
        },
        {
          name: "Neha Sadruddin",
          designation: "Project BIM Manager",
          location: "Grimshaw",
          description:
            "Currently working as a Project BIM Manager at Grimshaw. Also worked at Bjarke Ingels group.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/neha.png`,
          icon: "/icons/misc/grimshaw.svg",
          iconWhite: "/icons/mentor/grimshaw.svg",
          iconSize: {
            width: "120",
            height: "30",
          },
        },
        {
          name: "Salman Naqvi",
          designation: "Associate Architect ",
          location: "Populous",
          description: "A BIM expert, currently an Associate architect at Populous.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/salman.png`,
          icon: "/icons/misc/populous.svg",
          iconWhite: "/icons/mentor/populous.svg",
          iconSize: {
            width: "140",
            height: "40",
          },
        },
        {
          name: "Abhishek Ray",
          designation: "Ex - BIM Lead ",
          location: "SpaceMatters",
          description:
            "Experienced in BIM workflows. Invited as guest lecturer at SPA Bhopal for BIM as a Project Management Solution.",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/abhishek.png`,
          icon: "/icons/misc/spacematters.svg",
          iconWhite: "/icons/mentor/spacematters.svg",
          iconSize: {
            width: "130",
            height: "40",
          },
        },
      ],
    },
    careerServices: {
      sectionHeading: {
        title: "Career Services",
        heading: (
          <span>
            Prepare for a{" "}
            <span style={{ color: theme.colors.purple["700"] }}>rewarding career</span>
          </span>
        ),
        description: (
          <span>
            From scouting for jobs to nailing your interviews, you'll have our support every step of
            the way so that you're set up for success.
          </span>
        ),
      },
      serviceCardData: [
        {
          icon: (
            <Icon
              color={theme.colors.purple[700]}
              type={"Scaled - N - Menu"}
              varient={"scaled"}
              size={48}
            />
          ),
          number: "01.",
          heading: "Network with hiring partners",
          description: "Network with hiring partners via a dedicated placements team.",
        },
        {
          icon: (
            <Icon
              color={theme.colors.purple[700]}
              type={"Scaled - N - User - Correct"}
              varient={IconVarient.scaled}
              size={48}
            />
          ),
          number: "02.",
          heading: "Choose your Career Guidance",
          description: "Choose your career guidance to get personalised career counselling.",
        },
        {
          icon: (
            <Icon
              color={theme.colors.purple[700]}
              type={"Scaled - N - Mic"}
              varient={IconVarient.scaled}
              size={48}
            />
          ),
          number: "03.",
          heading: "Sit for 1:1 mock interviews",
          description: "Sit for 1:1 mock interviews and application guidance sessions.",
        },
        {
          icon: (
            <Icon
              color={theme.colors.purple[700]}
              type={"Scaled - N - Notebook"}
              varient={IconVarient.scaled}
              size={48}
            />
          ),
          number: "04.",
          heading: "Get Structured Feedback",
          description: "Get structured feedback on your resume and portfolio.",
        },
      ],
    },
  },
  enrollment: {
    applicationSteps: [
      {
        step: "Step 1",
        heading: <span>Apply for the course</span>,
        description: (
          <span>Register by entering your name, contact details, and professional background.</span>
        ),
      },
      {
        step: "Step 2",
        heading: (
          <span>
            Speak to an <br />
            academic counsellor
          </span>
        ),
        description: (
          <span>
            Explore how this course can benefit your career and get answers to all your queries.
          </span>
        ),
      },
      {
        step: "Step 3",
        heading: <span>Submit application to check eligibility</span>,
        description: <span>Ensure all criteria match up to the course requirements.</span>,
      },
      {
        step: "Step 4",
        heading: (
          <span>
            Complete payment and
            <br />
            reserve your seat
          </span>
        ),
        description: (
          <span>Secure your spot as a Novatr learner and kickstart your BIM journey!</span>
        ),
      },
    ],
  },
  pricingInfo: {
    actualFee: "1,75,000",
    discountedFee: "1,60,000",
    actualFeeInDollars: "2,500",
    discountedFeeInDollars: "2,250",
    bookingAmountInDollars: "500",
    bookingAmount: "15,000",
    courseInclusions: [
      "30+ live sessions and 15+ bonus sessions",
      "45+ assignments",
      "1 RIBA-structured capstone project",
      "Career counselling and interview prep",
      "Certificate of Completion",
    ],
  },
  customerReviews: {
    videoTestimonials: [
      {
        name: "Abhishek Madhavan",
        designation: "Offered Architect role at DAR, Pune",
        videoSrc: `${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonial_Abishek.mp4`,
        badgeColor: "purple",
        badgeText: "30% Salary Hike",
        badgeIcon: "trend-up-01",
        imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/nextImageExportOptimizer/abhishek-opt-600.WEBP`,
      },
      {
        name: "Ashish Kumar",
        designation: "Offered Jr. BIM Architect role at Techture, Indore",
        videoSrc: `${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonial_Ashish.mp4`,
        badgeColor: "green",
        badgeText: "First Job",
        badgeIcon: "rocket-02",
        imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/nextImageExportOptimizer/ashish-opt-600.WEBP`,
      },
      {
        name: "Dhairya Gupta",
        designation: "Offered BIM Architect role at The BIM Engineers",
        videoSrc: `${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonial_Dhairya.mp4`,
        badgeColor: "warning",
        badgeText: "60% Salary Hike",
        badgeIcon: "trend-up-01",
        imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/nextImageExportOptimizer/dhairya-opt-600.WEBP`,
      },
      {
        name: "Navneet Singh",
        designation: "Offered Architect role at FivD, Gurgaon",
        videoSrc: `${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonial_Navneet.mp4`,
        badgeColor: "purple",
        badgeText: "30% Salary Hike",
        badgeIcon: "trend-up-01",
        imgSrc: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/nextImageExportOptimizer/navneet-opt-600.WEBP`,
      },
    ],
    testimonials: [
      {
        name: "Bharti Dilip",
        userImage: {
          alt: "Bharti",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/bharti.png`,
        },
        badgeIcon: "trend-up-01",
        badgeColor: "primary",
        badgeText: "Current Batch",
        designation: "Practicing Architect",
        description:
          "A huge batch of students, yet each of us has an industry guide to solve our silliest doubts which is extremely helpful. The best mentors teaching the practical issues of projects in BIM. I’m glad that I have signed up for this course.",
        // cohort: "Review of Rhino and Grasshopper 101, May Cohort’21",
      },
      {
        name: "Pranav Prakash",
        userImage: {
          alt: "Pranav",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/pranav.png`,
        },
        badgeText: "Current Batch",
        badgeIcon: "trend-up-01",
        badgeColor: "primary",
        designation: "Practicing Architect, Pakistan",
        description:
          "Enrolling to the BIM Professional course has been an amazing experience for me. I feel excited to learn with Oneistox. The one thing that this class gives me is to dive into the AEC industry with full confidence.",
        // cohort: "Review of Rhino and Grasshopper 101, May Cohort’21",
      },
      {
        name: "Bhairavi Purohit",
        userImage: {
          alt: "Bhairavi",
          src: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/bim/testimonials/bhairavi.png`,
        },
        badgeText: "First Job",
        badgeIcon: "rocket-02",
        badgeColor: "success",
        designation: "BIM Coordinator, Colliers ",
        description:
          "I can now see a new perspective of architecture after joining this course. After getting a wider view of the BIM industry from mentors, I realized BIM is as vast as an ocean – and definitely worth diving into!",
        // cohort: "Review of Rhino and Grasshopper 101, May Cohort’21",
      },
    ],
  },
  faqs: {
    faqsAccordiansData: [
      {
        header: "Why is the course 6 months long?",
        description:
          "The course is designed to take you from being a BIM beginner to a BIM expert. In 6 months, we ensure a comprehensive understanding of the basics of BIM, topic specialization based on your field, and a collaborative capstone project.",
      },
      {
        header: "How much time should I dedicate in a week?",
        description:
          "Every week, you will have two live classes – one on Wednesday and another on Saturday, from 7.30 PM IST to 10.30 PM IST. In addition, you will have recorded lessons throughout the week with small activities. We expect you to put in a minimum of 7–8 hours of effort (depending on your speed) in a week to fully grasp and apply the concepts of BIM. We also provide additional activities and resource work for those looking to go above and beyond.",
      },
      {
        header: "Is the course content recorded or live?",
        description:
          "The course offers an engaging mix of both. Throughout the week, recorded content and practice files will be available to you for learning at your own pace and schedule. Live sessions will be conducted on Wednesdays and Saturdays for mentor interaction, query resolution, guest lectures and hands-on activities.",
      },
      {
        header: "In which language is the course delivered?",
        description: "The course content will be delivered in English.",
      },
      {
        header: "How do I get my queries resolved during the course?",
        description:
          "We have a 24x7 query resolution service. You will be assigned a learning coordinator whom you can contact for any query during the course.",
      },
      {
        header: "Do I get assistance once the course is completed?",
        description:
          "On joining a Oneistox course you get lifetime access to a closed messaging group that includes your batchmates and learning coordinators. This means you always have a group of people with whom you can discuss ideas and troubleshoot problems.",
      },
      {
        header: "Does the program guarantee a job?",
        description:
          "The course provides you with practical experience on a live project that significantly enhances your job prospects. Our team also provides guidance on building your resume and portfolio for a successful BIM career. In addition, Novatr's hiring partners recruit graduates directly to their companies. Successful completion of the course therefore equips you for growth in the BIM field.",
      },
      {
        header: "Is the certificate accepted by AEC firms?",
        description:
          "You will be trained for the Autodesk Professional Certification, which is widely recognized around the world.",
      },
      {
        header: "Do I need to have any pre-requisite knowledge to attend the course?",
        description:
          "Prior knowledge of BIM or its tools is not required as the course is structured in a beginner-friendly way. However, since this course is designed for professionals or students in their final year of graduation, it is expected that you would have a basic understanding of construction methods and materials.",
      },
      {
        header: "I don't have software installed on my laptop. Can I still attend the course?",
        description:
          "We understand that some of you might be new to the softwares. Our support team will be available to guide you throughout. Before the course commences, we make sure to help you install the relevant softwares and plugins and also get acquainted with them so that you're well-prepared.",
      },
      {
        header: "How do I pay for the course?",
        description:
          "Indian nationals can pay the full amount via online banking, debit/credit cards, UPI or opt for EMI. Foreign nationals can pay the full amount via debit/credit cards or PayPal, or in instalments via credit card. For any payment-related queries, please contact our team at +91-9315788823 or write to support@novatr.com",
      },
      {
        header: "Can I pay the course fee in instalments?",
        description:
          "Yes. Learners from India can opt-in for 3, 6, and 12-month EMI options after downpayment. Learners from the rest of the world can opt-in for a 3 or 6-month instalment plan.",
        // "Yes. Indian nationals can opt for either a 6-month EMI (₹21,000 per month) or a 12-month EMI (₹10,500 per month) after a down payment of ₹19,000. Foreign nationals can opt for a 6-month instalment plan (USD 250 per month) after a down payment of $500.",
      },
      {
        header: "How does fee payment in instalments work?",
        description:
          "To understand the instalment options available, please refer to the fee structure section of this page. Candidates must complete the loan/instalment approval process within 7 days of making the down payment and pay the first instalment 15 days before the course-commencement. Failure to do so will result in the enrolment being rescinded.",
      },
      {
        header: "Will I have to pay any extra amount if I opt for EMI?",
        description:
          "Note that candidates who make the full payment in advance are able to avail a fee waiver. However, if you prefer EMI, you can find the details on the fee structure section of this page.",
      },
      {
        header: "What is the refund policy for this course?",
        description:
          "As stated in our Terms and Conditions, any down payment made is not refundable. However, if you have made a partial or full payment and would like a refund, please send an email to support@novatr.com with the subject line 'Refund'. Please note that this should be done at least 25 days before the course commencement date. We will initiate the refund within 30 working days of accepting the refund request. Also, please be informed that a refund will not be possible once the EMI loan has been processed. You will be liable to complete the loan process even if you discontinue the course. Failure to pay back the loan will affect the participants' CIBIL score.",
      },
      {
        header: "What is the refund policy on the EMI scheme?",
        description:
          "A refund will not be possible once the EMI loan has been processed. You will be liable to complete the loan process even if you discontinue the course. Failure to pay back the loan will affect the participants' CIBIL score.",
      },
    ],
  },
};
export default BIMPageData;
