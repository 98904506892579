import Typography from "@/design-system/Typography";
import styled from "styled-components";

export const TruncateTypography = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ truncateLine }: { truncateLine?: number }) =>
    truncateLine || 2};
`;
