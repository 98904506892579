import { FC } from "react";
import { StyledPracticalTrainingCard } from "./practicaltraining.styles";
import { PracticalTrainingCardProps } from "./types";

const PracticalTrainingCard: FC<PracticalTrainingCardProps> = ({
  children,
  className,
}) => {
  return (
    <StyledPracticalTrainingCard className={className}>
      {children}
    </StyledPracticalTrainingCard>
  );
};

export default PracticalTrainingCard;
