import Box from "@/design-system/Box";
import PrimaryButton from "@/design-system/Button/PrimaryButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import ExportedImage from "next-image-export-optimizer";
import Link from "next/link";
import styled from "styled-components";
import { BecomeCardStyle } from "./becomeCard.styles";

const DetailsStyle = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  padding: 40px 32px;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 60%;
    padding: 40px;
  }
`;

const PictureStyle = styled(Box)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    width: 40%;
    height: 100%;
    overflow: hidden;
    border-radius: 0px 8px 8px 0px;
    position: relative;
  }
`;

const BecomeCard = ({
  titleText,
  subText,
  link,
  imgSrc,
  imgAlt,
}: {
  titleText: string;
  subText: string;
  link: string;
  imgSrc: string;
  imgAlt: string;
}) => {
  return (
    <BecomeCardStyle>
      <DetailsStyle flexDirection={"column"}>
        <Box>
          <Typography
            varient="bodyl"
            weightVarient="regular"
            color={theme.colors.gray[700]}
            mt={"8px"}
          >
            {titleText}
          </Typography>
          <Typography varient="heading3" weightVarient="medium">
            {subText}
          </Typography>
        </Box>
        <Box>
          <Link href={link}>
            <PrimaryButton
              label="Learn More"
              className="button__learn_more"
              mt={"48px"}
              size="sm"
              suffixIcon={
                <Icon
                  color={theme.colors.base.white}
                  type={"arrow-narrow-right"}
                  varient={IconVarient.duotone}
                />
              }
            />
          </Link>
        </Box>
      </DetailsStyle>
      <PictureStyle>
        <ExportedImage
          fill
          alt={imgAlt}
          src={imgSrc}
          style={{ objectFit: "cover" }}
        />
      </PictureStyle>
    </BecomeCardStyle>
  );
};

export default BecomeCard;
