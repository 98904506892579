import Box from "@/design-system/Box";
import PrimaryButton from "@/design-system/Button/PrimaryButton";
import CourseCard from "@/design-system/CourseCard";
import Divider from "@/design-system/Divider";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import ExportedImage from "next-image-export-optimizer";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

const ImageBoxStyle = styled(Box)`
  position: relative;
  height: 120px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 200px;
  }
`;

const DetailsBoxStyle = styled(Box)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 16px;
  padding: 16px;
  //height: calc(100% - 120px);
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 24px 32px 40px 32px;
    height: calc(100% - 176px);
  }
`;

interface CardComponentProps {
  idx: number;
  courseType: string;
  courseSubText: string;
  adText: string;
  programDuration?: string;
  nextCohort?: string;
  imgSrc: string;
  imgAlt: string;
}

const CourseCardComponent = ({
  courseType,
  adText,
  programDuration,
  nextCohort,
  imgSrc,
  idx,
  imgAlt,
}: CardComponentProps) => {
  let courseName;
  let link = {};
  const router = useRouter();
  switch (courseType) {
    case "BIM":
      courseName = <span>BIM Professional Course for Architects</span>;
      link = {
        pathname: "/courses/building-information-modelling",
        query: router.asPath,
      };
      break;
    case "MCD":
      courseName = <span>Master Computational Design Course</span>;
      link = {
        pathname: "/courses/computational-design",
        query: router.asPath,
      };
      break;
    case "BIM_C":
      courseName = <span>BIM Professional Course for Civil Engineers</span>;
      link = {
        pathname: "/courses/bim-for-civil-engineers",
        query: router.asPath,
      };
      break;
  }
  return (
    <Link href={link}>
      <CourseCard idx={idx}>
        <ImageBoxStyle>
          <ExportedImage
            fill
            src={imgSrc}
            alt={imgAlt}
            style={{ objectFit: "cover" }}
          />
          {/* <BadgeBoxStyle>{badgeSelected}</BadgeBoxStyle> */}
        </ImageBoxStyle>
        <DetailsBoxStyle>
          <Box gridColumn={"1 / span 12"}>
            <Typography
              varient="heading2"
              mdVarient="heading2"
              mdWeightVarient="semibold"
              weightVarient="semibold"
              color={theme.colors.primary[900]}
            >
              {courseName}
            </Typography>
          </Box>
          {/* <Box gridColumn={"1 / span 12"}>
          <Typography
            mt={"8px"}
            varient={"bodyxs"}
            weightVarient={"semibold"}
            color={theme.colors.primary[700]}
          >
            {courseSubText}
          </Typography>
        </Box> */}
          <Box
            mt={{ _: "24px", md: "16px" }}
            display={{ _: "none", md: "unset" }}
            gridColumn={{ _: "1 / span 6", md: "span 12" }}
          >
            <Typography
              varient={"captionl"}
              weightVarient={"medium"}
              color={theme.colors.gray[500]}
            >
              Program Duration
            </Typography>
            <Typography varient={"bodyxs"} weightVarient="semibold">
              {programDuration}
            </Typography>
          </Box>
          <Box
            mt={{ _: "24px", md: "16px" }}
            display={{ _: "none", md: "unset" }}
            gridColumn={{ _: "span 6", md: "span 12" }}
          >
            <Typography
              varient={"captionl"}
              weightVarient={"medium"}
              color={theme.colors.gray[500]}
            >
              Next Cohort
            </Typography>
            <Typography varient={"bodyxs"} weightVarient={"semibold"}>
              {nextCohort}
            </Typography>
          </Box>
          <Box
            gridColumn={{ _: "span 12" }}
            mt={{ _: "24px", md: "16px" }}
            display={{ _: "none", md: "unset" }}
          >
            <Divider
              style={{
                width: "100%",
                borderColor: theme.colors.grayWarm[300],
              }}
            />
          </Box>
          <Box
            gridColumn={{ _: "span 12" }}
            mt={{ _: "8px", md: "16px" }}
            mb={{ _: "24px", md: "16px" }}
            display={{ _: "none", md: "unset" }}
          >
            <Typography
              varient={"bodyxs"}
              weightVarient={"regular"}
              color={theme.colors.grayWarm[600]}
            >
              {adText}
            </Typography>
          </Box>
          <Box gridColumn={{ _: "span 12" }}>
            <Link href={link}>
              <PrimaryButton
                label="Learn More"
                className="button__learn_more"
                size={"lg"}
                style={{ marginTop: "24px", width: "100%" }}
                suffixIcon={
                  <Icon
                    color={theme.colors.base.white}
                    type={"arrow-narrow-right"}
                    varient={IconVarient.duotone}
                  />
                }
              />
            </Link>
          </Box>
        </DetailsBoxStyle>
      </CourseCard>
    </Link>
  );
};

export default CourseCardComponent;
