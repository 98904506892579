import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import { Fragment } from "react";
import Marquee from "react-fast-marquee";

const MarqueComponent = ({
  margueData,
  pageTheme,
}: {
  margueData: any[];
  pageTheme: any;
}) => {
  return (
    <Box>
      <Marquee gradientWidth={100} gradient={true} speed={100} direction="left">
        <FlexContainer alignItems={"center"}>
          {margueData.map((md, key) => {
            return (
              <Fragment key={key}>
                <Box ml={"48px"} mr={"8px"} key={key}>
                  <Icon
                    type={"star-05"}
                    varient={IconVarient.solid}
                    size={40}
                    color={
                      pageTheme
                        ? theme.colors.blue[key % 2 == 0 ? 900 : 700]
                        : theme.colors.primary[key % 2 == 0 ? 800 : 700]
                    }
                    stokeColor={"none"}
                  />
                </Box>
                <Typography
                  varient={"displays"}
                  color={
                    pageTheme
                      ? theme.colors.blue[key % 2 == 0 ? 900 : 700]
                      : theme.colors.primary[key % 2 == 0 ? 800 : 700]
                  }
                  weightVarient={"semibold"}
                  as={"h3"}
                >
                  {md}
                </Typography>
              </Fragment>
            );
          })}
        </FlexContainer>
      </Marquee>
    </Box>
  );
};
const SliderText = ({ data, pageTheme }: any) => {
  return (
    <Box width={"100%"} overflow={"hidden"}>
      <Box>
        <MarqueComponent margueData={data} pageTheme={pageTheme} />
      </Box>
    </Box>
  );
};

export default SliderText;
