import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import FlexContainer from "@/design-system/FlexContainer";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";
import SectionHeading from "@/design-system/SectionHeading";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

import PlacementCardComponent from "./PlacementCardComponent";

const ImageStyleBox = styled(FlexContainer)`
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;
  margin-top: 24px;
`;

const PlacementAssistance = () => {
  return (
    <Container>
      <GridContainer padding={{ _: "32px 8px 40px 8px", md: "0px 0px 24px 0px" }}>
        <Box gridColumn="span 12">
          <Box
            style={{
              position: "absolute",
              zIndex: "6",
              marginLeft: "-60px",
              marginTop: "-320px",
            }}
          >
            {/* <LeftBarMultiple
              alignStart="443px"
              alignMiddle="947px"
              alignEnd="227px"
            /> */}
          </Box>
          <Box
            height={"240px"}
            position="absolute"
            top={-220}
            left={-28}
            display={{ _: "none", md: "block" }}
          >
            <Box
              display={"inline-block"}
              height={"100%"}
              width={"3px"}
              backgroundImage={theme.gradients.misc180[5]}
            />
          </Box>
          <Box position="absolute" display={{ _: "none", md: "block" }} top={30} left={-48}>
            <Icon
              type={"Scaled - N - Briefcase"}
              varient={IconVarient.scaled}
              size={40}
              color={theme.colors.purple[600]}
              stokeColor={theme.colors.purple[600]}
            />
          </Box>
          <SectionHeading
            streak
            streakTitle={"Placement Assistance"}
            pmd={"16px 32px 16px 32px"}
            heading={
              <span>
                <span
                  style={{
                    color: theme.colors.purple[700],
                  }}
                >
                  Land the Job{" "}
                </span>
                You Dreamed Of
              </span>
            }
            description={
              <span>
                Scouting relevant opportunities, networking with companies, and landing interviews –
                you'll ace it all with our personalised career guidance.
              </span>
            }
          />
        </Box>
        <GridColumn xs="span 12" md="1 / span 6" mt={{ _: "24px", md: "16px" }}>
          <PlacementCardComponent
            badgeText="83% Salary Hike"
            type="firstJob"
            name="Maannav Jaisingh"
            designation="Placed at DAR, as an Architect"
            description="The course was an excellent blend of theoretical concepts and practical application. It’s an excellent investment for those looking to enhance their career prospects and stay at the forefront of the rapidly evolving AEC industry."
            img={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/testimonials/manav.png`}
          />
        </GridColumn>
        <GridColumn xs="span 12" md="span 6" mt={{ _: "16px", md: "16px" }}>
          <PlacementCardComponent
            badgeText="164% Salary Hike"
            type="salaryHike"
            name="Ravi Kumar"
            designation="Placed at DAR, as an Architect"
            img={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/testimonials/ravi.png`}
            description="After completing the BIM course, I was very grateful for the Novatr team’s guidance, interview training, and assistance in finding the right employment opportunities. They were extremely supportive throughout the process."
          />
        </GridColumn>
        <Box
          gridColumn="span 12"
          padding={{ _: "24px 8px", md: "56px 24px" }}
          mt={{ _: "24px", md: "16px" }}
        >
          <Box>
            <Typography varient="bodys" weightVarient="regular">
              Our BIM graduates landed placements at top MNCs with an average pay hike of 60%!
            </Typography>
            <FlexContainer alignItems={"center"}>
              <Link
                href="https://www.novatr.com/blog/bim-graduate-placements"
                target={"_blank"}
                className={"link__"}
              >
                <Typography
                  varient="bodys"
                  weightVarient="semibold"
                  mt={{ _: "8px", md: "0px" }}
                  color={theme.colors.gray[700]}
                >
                  Click to learn more
                </Typography>
              </Link>
              <FlexContainer marginLeft={"14px"} alignItems={"center"}>
                <Icon type={IconType["arrow-narrow-right"]} varient={IconVarient.line} size={16} />
              </FlexContainer>
            </FlexContainer>
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          padding={{ _: "8px 0px", md: "56px 24px" }}
          mt={{ _: "24px", md: "16px" }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={{ _: "center", md: "center" }}
          alignItems={{ _: "center", md: "flex-start" }}
        >
          <Typography
            // mb={"24px"}
            ml={{ md: "16px" }}
            varient="bodyxs"
            weightVarient="regular"
            color={theme.colors.gray[600]}
          >
            Our Learners are placed at
          </Typography>
          <FlexContainer
            flexWrap={"wrap"}
            width="100%"
            justifyContent={{
              _: "center",
              md: "flex-start",
              lg: "space-between",
            }}
            // style={{ gap: "40px" }}
          >
            <ImageStyleBox>
              <ExportedImage
                alt={"AECOM"}
                height={32}
                width={111}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/atkins.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"UNS"}
                height={32}
                width={60}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/bdp.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"RSP"}
                height={32}
                width={74}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/colliers.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"Populous"}
                height={32}
                width={165}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/dar_group.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"Henning Larsen"}
                height={32}
                width={101}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/khatib_alami.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"Buro Haffold"}
                height={32}
                width={117}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/srinsoft.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"External Reference"}
                height={32}
                width={108}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/tata_consulting.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"Benoy"}
                height={32}
                width={116}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/techture.svg`}
              />
            </ImageStyleBox>
            <ImageStyleBox>
              <ExportedImage
                alt={"HH"}
                height={32}
                width={85}
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/placements/the_bim_engineers.svg`}
              />
            </ImageStyleBox>
          </FlexContainer>
        </Box>
      </GridContainer>
    </Container>
  );
};

export default PlacementAssistance;
