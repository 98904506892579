import { theme } from "@/theme";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Box from "../Box";
import Icon from "../Icon";
import { IconVarient } from "../Icon/types";
import Typography from "../Typography";

export const ReadMore = ({ children, pageTheme }: any) => {
  const [readmore, setReadmore] = useState("close");
  const varients = {
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        ease: "easeIn",
        duration: 0.5,
      },
    },
    close: {
      height: 0,
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };
  const iconVarients = {
    open: {
      rotate: 0,
    },
    close: {
      rotate: 180,
    },
  };
  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          initial={{
            height: 0,
            opacity: 0,
          }}
          variants={varients}
          animate={readmore}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              ease: "easeOut",
              duration: 0.5,
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>

      <Box
        bg={"inherit"}
        display={"inline-flex"}
        mt={"32px"}
        className="pointer"
        onClick={() => {
          setReadmore(readmore === "open" ? "close" : "open");
        }}
      >
        <Typography
          varient="bodyxs"
          weightVarient="semibold"
          color={pageTheme?.readMoreColor || theme.colors.primary[800]}
          mr={"8px"}
        >
          Read {readmore === "open" ? "Less" : "More"}
        </Typography>
        <Box>
          <AnimatePresence mode="wait">
            <motion.div
              initial={{
                rotate: 0,
              }}
              variants={iconVarients}
              animate={readmore}
              exit={{
                height: 0,
                opacity: 0,
                transition: {
                  ease: "easeOut",
                  duration: 0.5,
                },
              }}
            >
              <Icon type={"chevron-up"} size={16} varient={IconVarient.solid} />
            </motion.div>
          </AnimatePresence>
        </Box>
      </Box>
    </>
  );
};

export default ReadMore;
