import { theme } from "@/theme";
import styled from "styled-components";

export const StyledCourseCard = styled.div<any>`
  background-color: white;
  padding: 8px;
  border-radius: 16px;
  //min-height: 436px;
  height: 100%;
  box-shadow: ${theme.shadows.primary.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 16px;
    border-radius: 16px;
    min-height: 508px;
    max-width: 576px;
    margin-left: ${({ idx }) => (idx === 0 ? "auto" : "inherit")};
    margin-right: ${({ idx }) => (idx === 1 ? "auto" : "inherit")};
  }
`;
