import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import { openPopup } from "@/actions/popup";

import Box from "@/design-system/Box";
import GrayButton from "@/design-system/Button/GrayButton";
import PrimaryButton from "@/design-system/Button/PrimaryButton";
import CommonHeroSection from "@/design-system/CommonHeroSection";
import Container from "@/design-system/Container";
import FlexContainer from "@/design-system/FlexContainer";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import FadeInWhenVisible from "@/design-system/Transitions/FadeInWhenVisible";
import Typography from "@/design-system/Typography";

import { useAppDispatch } from "@/hooks/hooks";

import { theme } from "@/theme";

const CTAStyleBox = styled.div`
  display: grid;
  grid-template-columns: max-content;
  grid-gap: 16px;
  //margin-bottom: 85px;
  //margin-bottom: ${theme.spacing(14)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: max-content max-content;
    margin-bottom: 85px;
    margin-bottom: ${theme.spacing(14)};
  }
`;

const CTAApplyNow = styled(GrayButton)`
  order: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-row: 1;
  }
`;

const HeroImgStyle = styled(Box)`
  display: none;
  position: absolute;
  z-index: 5;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    right: -52px;
    top: 80px;
    width: 513px;
    height: 504px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: 67px;
    right: -80px;
    width: 613px;
    height: 604px;
  }
`;

const UnionTopStyle = styled(Box)`
  position: absolute;
  left: -330px;
  top: -179px;
  width: 490px;
  height: 490px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    left: -330px;
    top: -344px;
    width: 490px;
    height: 490px;
  }
`;
const UnionBottomStyle = styled(Box)`
  position: absolute;
  display: none;
  z-index: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    right: -127px;
    top: 622px;
    width: 490px;
    height: 490px;
  }
`;

const TypographyStyle = styled(Typography)`
  margin-bottom: 24px;
  text-align: center;
  color: ${theme.colors.gray[200]};
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: left;
    display: block;
  }
`;

const logoList = [
  { logo: "rsp.svg", title: "", width: "54", alt: "RSP" },
  { logo: "populous_hero.svg", title: "", width: "101", alt: "Populous" },
  { logo: "autodesk.svg", title: "", width: "113", alt: "Autodesk" },
  { logo: "uns.svg", title: "", width: "44" },
  { logo: "benoy_hero.svg", title: "", width: "53" },
  { logo: "aecom_hero.svg", title: "", width: "55" },
  { logo: "heather.svg", title: "", width: "133" },
  { logo: "external.svg", title: "", width: "72" },
];

const HeroSection = () => {
  const dispatch = useAppDispatch();

  return (
    <Box backgroundColor={theme.colors.primary[25]}>
      <CommonHeroSection pt="0px">
        <UnionTopStyle>
          <ExportedImage
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union3.svg`}
            fill
            alt=""
          />
        </UnionTopStyle>
        <UnionBottomStyle>
          <ExportedImage
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/union5.svg`}
            fill
            alt=""
          />
        </UnionBottomStyle>
        <Container>
          <FadeInWhenVisible zIndex={5} duration={0.2}>
            <HeroImgStyle>
              <ExportedImage
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/hero.png`}
                alt="Gain industry-relevant skills with cohort-based learning."
                width={513}
                height={580}
                priority
              />
            </HeroImgStyle>
          </FadeInWhenVisible>
          <GridContainer>
            <GridColumn md={"span 6"}>
              {" "}
              {/* this is 7 in figma */}
              <Typography
                varient="displays"
                mdVarient="displayl"
                weightVarient="semibold"
                mdWeightVarient="semibold"
                color="white"
                mb={{ _: "24px", md: "28px" }}
                mt={{ _: "32px", md: "28px" }}
              >
                Empowering <span style={{ whiteSpace: "nowrap" }}>next-gen</span> architects and
                engineers towards career success
              </Typography>
              <Typography
                varient="bodys"
                mdVarient="bodyl"
                weightVarient="regular"
                mdWeightVarient="regular"
                color={theme.colors.warning[200]}
                mb={"32px"}
              >
                Online certification courses that set you up for success. Gain industry-relevant
                skills with cohort-based learning.
              </Typography>
              <CTAStyleBox>
                <a href={"#courses"}>
                  <CTAApplyNow
                    fullWidth
                    size={"lg"}
                    label="Explore Courses"
                    className="button__explore_courses"
                    suffixIcon={
                      <Icon
                        color={theme.colors.base.black}
                        type={"arrow-narrow-right"}
                        varient={IconVarient.duotone}
                      />
                    }
                  />
                </a>
                <PrimaryButton
                  onClick={() => dispatch(openPopup("RequestCallBackPopup"))}
                  label="Request a Call Back"
                  size="lg"
                  className="button__request_a_call_back"
                  suffixIcon={
                    <Icon
                      color={theme.colors.base.white}
                      type={"phone-call-01"}
                      varient={IconVarient.duotone}
                    />
                  }
                />
              </CTAStyleBox>
            </GridColumn>
            <GridColumn>
              <TypographyStyle as={"h6"}>Learn from experts working at</TypographyStyle>
              <FlexContainer
                flexWrap={"wrap"}
                maxWidth={500}
                style={{ gap: "16px" }}
                display={{ _: "none", md: "flex" }}
                zIndex={7}
                mt={{ _: "24px", md: "0px" }}
              >
                {logoList.map((list, key) => (
                  <Box display={"flex"} mr="16px" key={key}>
                    <ExportedImage
                      key={key}
                      // height={"32"}
                      // width={"90"}
                      height={"52"}
                      width={"50"}
                      src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/company-logos/white/${list.logo}`}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      priority
                      alt={list.title}
                    />
                  </Box>
                ))}
              </FlexContainer>
              <FlexContainer
                maxWidth={500}
                justifyContent={"space-between"}
                zIndex={7}
                display={{ _: "flex", md: "none" }}
                mt={{ _: "24px", md: "0px" }}
              >
                {logoList.slice(0, 4).map((list, key) => (
                  <Box display={"flex"} mr="16px" key={key}>
                    <ExportedImage
                      key={key}
                      height={"62"}
                      width={"50"}
                      src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/company-logos/white/${list.logo}`}
                      priority
                      alt={list.title}
                    />
                  </Box>
                ))}
              </FlexContainer>
              <FlexContainer
                maxWidth={500}
                justifyContent={"space-between"}
                zIndex={7}
                display={{ _: "flex", md: "none" }}
              >
                {logoList.slice(4, 8).map((list, key) => (
                  <Box display={"flex"} mr="16px" key={key}>
                    <ExportedImage
                      key={key}
                      height={"52"}
                      width={"50"}
                      src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/company-logos/white/${list.logo}`}
                      priority
                      alt={list.title}
                    />
                  </Box>
                ))}
              </FlexContainer>
            </GridColumn>
          </GridContainer>
        </Container>
      </CommonHeroSection>
    </Box>
  );
};

export default HeroSection;
