import { FC } from "react";
import {
  StyledHeroSection,
  StyledHeroSectionOuter,
} from "./CommonHeroSection.styles";
import { CommonHeroSectionProps } from "./types";

const CommonHeroSection: FC<CommonHeroSectionProps> = ({
  children,
  bg,
  page,
  pb,
  pt,
  py,
  height,
  overflow,
}) => {
  return (
    <StyledHeroSectionOuter>
      <StyledHeroSection
        pb={pb}
        pt={pt}
        py={py}
        bg={bg}
        page={page}
        height={height}
        overflow={overflow}
      >
        {children}
      </StyledHeroSection>
    </StyledHeroSectionOuter>
  );
};

export default CommonHeroSection;
