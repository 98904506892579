import { theme } from "@/theme";
import { truncate } from "@/utils/utils";
import ExportedImage from "next-image-export-optimizer";
import Link from "next/link";
import styled from "styled-components";
import Badge from "../Badge/badge";
import Box from "../Box";
import Typography from "../Typography";
import { TruncateTypography } from "../Utility/TruncateTypography/TruncateTypography";

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  /* z-index; 5; */
`;

const InnerImage = styled.div`
  position: relative;
  transition: 0.3s;
  width: 100%;
  height: 100%;
`;

interface CardWrapperProps {
  downloadPdf: boolean;
}

const CardWrapper = styled(Box)<CardWrapperProps>`
  border-radius: 8px;
  overflow: hidden;
  background-color: ${theme.colors.base.white};
  box-shadow: ${(props) => (props.downloadPdf ? "" : theme.shadows.primary.xs)};
  padding: 24px 16px;
  border: 1px solid transparent;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.colors.purple[600]};
  }
  &:hover ${InnerImage} {
    transform: scale(1.1);
  }
  &:hover ${ImageOverlay} {
    opacity: 1;
  }
  &:hover .para {
    color: ${theme.colors.purple[600]};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0px;
  }
`;

const BlogListingHorizontalCard = ({
  badgeText,
  para,
  authorImage,
  authorName,
  date,
  readingTime,
  blogImage,
  downloadPdf = false,
  url = "",
  ...rest
}) => {
  return (
    <CardWrapper downloadPdf={downloadPdf}>
      <Box display={{ _: "flex", md: "block" }} alignItems="center">
        <Box
          mr={{ _: "16px", md: 0 }}
          p={{ _: "unset", md: "24px" }}
          {...rest}
          width={{ _: "80%", md: "unset" }}
        >
          <Box mb="12px">
            {Array.isArray(badgeText) ? (
              badgeText.map((text: string) => {
                return (
                  <Badge
                    key={text}
                    size="xs"
                    colorVarient="gray"
                    badgeStyle="light"
                    text={text}
                  />
                );
              })
            ) : (
              <Badge
                size="xs"
                colorVarient="gray"
                badgeStyle="light"
                text={badgeText}
              />
            )}
          </Box>
          <Box mb={{ _: "20px", md: "20px" }} minHeight={"56px"}>
            <TruncateTypography
              color={theme.colors.gray[900]}
              varient="bodyl"
              weightVarient="medium"
            >
              <span className="para">{para}</span>
            </TruncateTypography>
          </Box>
          <Box width={"100%"} display={{ _: "block", md: "flex" }}>
            {authorName && (
              <Box
                title={authorName}
                display={{ _: "block", md: "inline-block" }}
                mb={{ _: "8px" }}
              >
                <>
                  <Box
                    display={"inline-block"}
                    borderRadius={"100%"}
                    overflow={"hidden"}
                    width={"16px"}
                    height={"16px"}
                    mr={"8px"}
                    top={"3px"}
                  >
                    {authorImage.src && downloadPdf && (
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        style={{ objectFit: "cover" }}
                        alt={authorImage.alt}
                        src={authorImage.src}
                      />
                    )}

                    {!downloadPdf && authorImage.src && (
                      <ExportedImage
                        style={{ objectFit: "cover" }}
                        alt={authorImage.alt}
                        fill
                        src={authorImage.src}
                        unoptimized
                      />
                    )}
                  </Box>

                  <Typography
                    color={theme.colors.gray[800]}
                    varient="captionl"
                    weightVarient="medium"
                  >
                    {truncate(authorName, 7)}
                  </Typography>
                </>
              </Box>
            )}
            {!downloadPdf && (
              <Box
                marginLeft={authorName ? "auto" : "unset"}
                mr="0px"
                display={{ _: "block", md: "inline-block" }}
              >
                <Typography
                  color={theme.colors.gray[600]}
                  varient="captionl"
                  weightVarient="medium"
                  mr={"8px"}
                >
                  {date}
                </Typography>
                <Box
                  display={"inline-block"}
                  height={"8px"}
                  width={"0px"}
                  border={`1px solid ${theme.colors.gray[400]}`}
                  mr={"8px"}
                />
                <Typography
                  color={theme.colors.gray[600]}
                  varient="captionl"
                  weightVarient="medium"
                >
                  {readingTime}
                </Typography>
              </Box>
            )}
            {downloadPdf && (
              <Box
                marginLeft={authorName ? "auto" : "unset"}
                mr="0px"
                display={{ _: "block", md: "inline-block" }}
              >
                <Typography
                  color={theme.colors.gray[600]}
                  varient="captionl"
                  weightVarient="medium"
                  mr={"8px"}
                >
                  {readingTime}
                </Typography>
                <Box
                  display={"inline-block"}
                  height={"8px"}
                  width={"0px"}
                  border={`1px solid ${theme.colors.gray[400]}`}
                  mr={"8px"}
                />
                <Link href={url}>
                  <Typography
                    color={theme.colors.purple[800]}
                    varient="captionl"
                    weightVarient="medium"
                    style={{ textDecoration: "underline" }}
                  >
                    {"Read More"}
                  </Typography>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
        <Box style={{ overflow: "hidden" }}>
          <InnerImage>
            <Box
              borderRadius={{ _: "8px", md: "0px" }}
              mx={"-1px"}
              overflow={"hidden"}
              minWidth={{ _: "100px", md: "100%" }}
              minHeight={{ _: "100px", md: "232px" }}
            >
              {downloadPdf ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  alt={blogImage?.alt}
                  width={"100px"}
                  height={"100px"}
                  src={blogImage?.src}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <ExportedImage
                  alt={blogImage?.alt}
                  fill
                  unoptimized={true}
                  src={blogImage?.src}
                  style={{ objectFit: "cover" }}
                />
              )}
            </Box>
            <ImageOverlay />
          </InnerImage>
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default BlogListingHorizontalCard;
