import { theme } from "@/theme";
import styled from "styled-components";
import { space } from "styled-system";
import { CommonHeroSectionProps } from "./types";

export const StyledHeroSectionOuter = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${theme.spacing(0)} ${theme.spacing(4)} 0 ${theme.spacing(4)};
  }
`;

export const StyledHeroSection = styled.div<CommonHeroSectionProps>`
  padding-top: ${({ pt }) => pt || "30px"};
  //padding-bottom: 57px;
  padding-bottom: 32px;
  height: ${({ height }) => height};
  overflow: ${({ overflow }) => overflow || "hidden"};
  position: relative;
  background-image: ${({ bg }) =>
    bg || theme.gradients.primaryGradients45deg[7]};
  color: ${theme.colors.base.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: ${({ pt }) => pt || "64px"};
    border-radius: ${theme.spacing(6)};
    padding-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: ${({ pt }) => pt || "120px"};
    padding-bottom: 120px;
  }
  ${space};
`;
