import Box from "@/design-system/Box";
import ImpactCard from "@/design-system/ImpactCard";
import FadeInWhenVisible from "@/design-system/Transitions/FadeInWhenVisible";
import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import { ReactElement } from "react";

interface ImpactCardComponent {
  Icon: ReactElement;
  stats: string;
  title: string;
}

const ImpactCardComponent = ({ Icon, stats, title }: ImpactCardComponent) => {
  return (
    <FadeInWhenVisible>
      <ImpactCard>
        <Box>
          <Typography
            varient="displayl"
            weightVarient="medium"
            color={theme.colors.purple[800]}
            as={"h5"}
          >
            {stats}
          </Typography>
          <Typography
            varient="bodyl"
            weightVarient="medium"
            color={theme.colors.gray[700]}
            style={{ marginTop: "16px" }}
          >
            {title}
          </Typography>
        </Box>
        <Box>{Icon}</Box>
      </ImpactCard>
    </FadeInWhenVisible>
  );
};

export default ImpactCardComponent;
