import { useState } from "react";

import { getBlogsData } from "@/client/OneistoxApiClient";

import Seo from "@/components/Seo";

import headDetails from "@/content/head";

import Box from "@/design-system/Box";

import CTABanner from "@/components/CoursePage/BIM/Footer/ctaBanner";
import Alumni from "@/components/Home/Alumni";
import Become from "@/components/Home/Become";
import ExpertMentors from "@/components/Home/ExpertMentors";
import HeroSection from "@/components/Home/HeroSection";
import InnovativeLearning from "@/components/Home/InnovativeLearning";
import OurCourses from "@/components/Home/OurCourses";
import OurImpact from "@/components/Home/OurImpact";
import PlacementAssistance from "@/components/Home/PlacementAssistance";
import PracticalTraining from "@/components/Home/PraticalTraining";
import Resources from "@/components/Home/Resources";
import PRSection from "@/components/Impact/PRSection";
import Layout from "@/components/Layout/Layout";
import WhatsappWidget from "@/components/Layout/WhatsappWidget";
import WhatsappWidgetMobile from "@/components/Layout/WhatsappWidgetMobile";
import BIMPageData from "@/content/courses/bim";

export const getStaticProps = async () => {
  const content = headDetails;
  const blogs = await getBlogsData();
  return { props: { data: content, blogsList: blogs.data } };
};

const Index = ({ data, blogsList }: any) => {
  const [ctaExplore, setCtaExplore] = useState(false);

  return (
    <Layout courseName="Home">
      <Seo
        data={data.home}
        title="Online Professional Courses for Designers, Architects & Engineers | Novatr"
      />
      <Box>
        <HeroSection />
      </Box>
      <OurImpact />
      <OurCourses ctaExplore={ctaExplore} />
      <ExpertMentors BIMData={BIMPageData} />
      <PracticalTraining />
      <PlacementAssistance />
      <InnovativeLearning />
      <Box overflow={"hidden"}>
        <Become />
        <Resources blogsList={blogsList} />
        <Alumni BIMData={BIMPageData.customerReviews} />
      </Box>
      <PRSection />
      <CTABanner
        bannerHeading="Ready to skyrocket your career?"
        bannerText="Your next chapter in AEC begins with Novatr!"
        bannerSubText="As you would have gathered, we are here to help you take the industry by storm with advanced, tech-first skills."
        ctaText="Explore Courses"
        className="button__explore_courses"
        ctaLink="#courses"
        setCtaExplore={setCtaExplore}
      />

      <WhatsappWidget />
      <WhatsappWidgetMobile />
    </Layout>
  );
};

export default Index;
