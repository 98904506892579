import { theme } from "@/theme";
import styled from "styled-components";

export const StyledCardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  transition: top 0.3s;
  padding: 24px 32px;
  border-radius: 8px;
  opacity: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: absolute;
    top: 100%;
    z-index: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    & * {
      color: #fff;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: ${theme.colors.base.white};
    transition: all 0.3s;
    width: 240px;
    margin-left: -240px;
    border-radius: 0 8px 8px 0;
  }
  &:hover .card-content {
    opacity: 1;
  }
`;

export const StyledMentorCard = styled.div`
  display: flex;
  transition: all 0.3s;
  /* padding-bottom: 10px; */
  margin-left: ${theme.spacing(2)};
  margin-right: ${theme.spacing(2)};
  border-radius: 16px;
  background-color: #ffffff;
  .overlayName {
    /* position: absolute;
    bottom: 0;
    text-align: center;
    padding: 22px;
    z-index: 1;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s; */
  }

  &:hover .overlayName {
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    overflow: hidden;
    min-width: 280px;
    &:hover ${StyledCardContent} {
      top: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    &:hover ${StyledCardContent} {
      margin-left: 0;
    }
  }
`;
