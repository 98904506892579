import { theme } from "@/theme";
import { FC } from "react";
import Box from "../Box";
import FlexContainer from "../FlexContainer";
import SizeShiftAnimation from "../Transitions/SideShiftAnimation";
import Typography from "../Typography";
import { Streak } from "./streak";
import { SectionHeadingProps } from "./types";

export const SectionHeadingHeader = ({ children }: any) => {
  return (
    <Typography
      varient="heading3"
      as={"h2"}
      weightVarient="semibold"
      color={theme.colors.gray[800]}
      mt={"8px"}
    >
      {children}
    </Typography>
  );
};

export const SectionHeadingDescription = ({ children }: any) => {
  return (
    <Typography
      varient="bodyl"
      weightVarient="regular"
      color={theme.colors.gray[600]}
      mt={"8px"}
    >
      {children}
    </Typography>
  );
};

export const SectionHeadingContent = ({ children }: any) => {
  return <Box maxWidth={{ _: "none", md: "804px" }}>{children}</Box>;
};

const SectionHeading: FC<SectionHeadingProps> = ({
  heading,
  description,
  className,
  streak,
  streakTitle,
  pxs,
  pmd,
  ...rest
}) => {
  return (
    <SizeShiftAnimation>
      <Box
        p={{ _: pxs || "16px 8px 16px 8px", md: pmd || "16px 16px 16px 64px" }}
        {...rest}
        className={className}
      >
        <FlexContainer>
          <Typography
            flex={"0 0 auto"}
            varient={"bodyl"}
            weightVarient={"regular"}
          >
            {streakTitle}
          </Typography>
          {streak && (
            <Streak
              backgroundImage={{
                _: theme.gradients.misc[5],
                md: "none",
              }}
              backgroundColor={{
                _: "none",
                md: theme.colors.base.black,
              }}
            />
          )}
        </FlexContainer>

        <SectionHeadingContent>
          <SectionHeadingHeader>{heading}</SectionHeadingHeader>
          {description && (
            <SectionHeadingDescription>{description}</SectionHeadingDescription>
          )}
        </SectionHeadingContent>
      </Box>
    </SizeShiftAnimation>
  );
};

export default SectionHeading;
