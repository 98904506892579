import EditorsPick from "@/components/LearningHub/EditorsPick";
import BlogListingHorizontalCard from "@/design-system/BlogListingCard/horizontalCard";
import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import SectionHeading from "@/design-system/SectionHeading";
import { theme } from "@/theme";
import dayjs from "dayjs";
import Link from "next/link";
import { useEffect, useState } from "react";

const Resources = ({ blogsList }: any) => {
  const [editorsPickList, setEditorsPickList] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const blogsData = blogsList;
      if (blogsData) {
        const blogs: any = blogsData;
        setEditorsPickList(
          blogs.filter((item: any) => item.tags.includes("Home-Editor's Pick"))
        );
      }
    })();
  }, []);
  return (
    <Box
      backgroundColor={theme.colors.gray[50]}
      padding={{ _: "32px 0px", md: "120px 0px" }}
    >
      <Container>
        <GridContainer>
          <Box gridColumn={"span 12"}>
            <SectionHeading
              streak
              streakTitle={"Resources"}
              heading={
                <span>
                  Learn More About
                  <span style={{ color: theme.colors.purple[700] }}>
                    {" "}
                    Your Industry{" "}
                  </span>
                </span>
              }
              description={
                <span>
                  Helpful insights on AEC niches, technology, industry trends,
                  and careers that you won't find anywhere else!
                </span>
              }
            />
          </Box>
        </GridContainer>
      </Container>
      {editorsPickList.length > 0 && (
        <EditorsPick page="home" blogsData={editorsPickList.slice(0, 5)} />
      )}

      <Container mt={{ _: "48px", md: "unset" }}>
        <GridContainer gridColumn={"span 12"} style={{ gridGap: "16px" }}>
          {editorsPickList.length > 5 &&
            editorsPickList.slice(5, 8).map((blog, key) => {
              return (
                <Box key={key} gridColumn={{ _: "span 12", md: "span 4" }}>
                  <Link
                    href={`/blog/${blog.url}`}
                    target={blog?.isHubspot ? "_blank" : "_self"}
                  >
                    <BlogListingHorizontalCard
                      badgeText={blog.categories__name}
                      para={blog.name}
                      authorImage={{
                        alt: blog.author__img,
                        src: `${process.env.NEXT_PUBLIC_CDN}/images/blog/authors/${blog?.author__img}`,
                      }}
                      authorName={
                        blog.author__firstName
                          ? `${blog.author__firstName}` +
                            " " +
                            `${blog.author__lastName}`
                          : false
                      }
                      date={dayjs(blog.createdAt).format(`DD MMM'YY`)}
                      readingTime={blog.readtime}
                      blogImage={{
                        alt: "",
                        src: `${process.env.NEXT_PUBLIC_CDN}/images/blog/blogHero/${blog?.img}`,
                      }}
                    />
                  </Link>
                </Box>
              );
            })}
        </GridContainer>
      </Container>
    </Box>
  );
};

export default Resources;
