import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import FlexContainer from "@/design-system/FlexContainer";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import PracticalTrainingCard from "@/design-system/PracticalTrainingCard";
import SectionHeading from "@/design-system/SectionHeading";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

import Highlight from "./Highlight";

const HighlightContainerStyle = styled(FlexContainer)`
  margin-top: 32px;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 72px;
    gap: 40px;
  }
`;
const FooterStyle = styled(FlexContainer)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;
    background-color: ${theme.colors.primary[900]};
    min-height: 108px;
  }
`;

const FooterStyleMobile = styled(FlexContainer)`
  background-color: ${theme.colors.primary[900]};
  min-height: 108px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 32px 70px 32px 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ColoredBackgroundStyle = styled(Box)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    background: ${theme.gradients.misc["3"]};
    height: 668px;
    width: 100%;
  }
`;

const PracticalTraining = () => {
  return (
    <>
      <ColoredBackgroundStyle />
      <Container marginTop={{ md: "-588px" }} marginBottom={{ md: "80px" }}>
        <GridContainer>
          <Box gridColumn="span 12">
            <PracticalTrainingCard>
              <GridColumn md="span 12">
                <GridContainer>
                  <GridColumn
                    xs="span 12"
                    md="span 6"
                    padding={{ _: "32px 8px", md: "88px 48px 88px 64px" }}
                  >
                    {/* <FlexContainer alignItems={"center"}>
                      <Typography
                        varient="bodyxs"
                        weightVarient="regular"
                        color={theme.colors.gray[600]}
                      >
                        Practical Training
                      </Typography>
                      <StreakStyleMobile></StreakStyleMobile>
                    </FlexContainer> */}
                    <SectionHeading
                      streak
                      pmd="16px 32px"
                      streakTitle="Practical Training"
                      heading={
                        <span>
                          Get Ahead with{" "}
                          <span
                            style={{
                              color: theme.colors.purple[600],
                              whiteSpace: "nowrap",
                            }}
                          >
                            Real-World Projects
                          </span>
                        </span>
                      }
                      description={
                        <span>
                          How do you learn to build a building? By building a building! Our courses
                          include live projects that significantly enhance your portfolio.
                        </span>
                      }
                    />
                    <HighlightContainerStyle>
                      <Highlight
                        icon={
                          <Icon
                            color={theme.colors.purple[700]}
                            //type={"globe-05"}
                            type={"Scaled - N - Location"}
                            varient={IconVarient.scaled}
                            size={56}
                          />
                        }
                        title="International </br>Exposure"
                        details="Our live projects are curated in partnership with leading AEC firms based in the US, Hong Kong and Portugal."
                      />
                      <Highlight
                        icon={
                          <Icon
                            color={theme.colors.purple[700]}
                            type={"Scaled - N - Gaming"}
                            varient={IconVarient.scaled}
                            size={56}
                          />
                        }
                        title="Gamified </br>Experience"
                        details="Take instructions from a client, pitch innovative solutions, and see your project unfold – all in a virtual simulation."
                      />
                    </HighlightContainerStyle>
                  </GridColumn>
                  <GridColumn
                    xs="span 12"
                    md="span 6"
                    alignItems="center"
                    justifyContent="center"
                    padding={{ _: "24px 23px", md: "80px 0px" }}
                    height="100%"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box style={{ aspectRatio: "576 / 480" }} width="100%">
                      <ExportedImage
                        src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/practical_training.png`}
                        fill
                        alt="Gamified Experience"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </GridColumn>
                </GridContainer>
                <FooterStyle>
                  <Typography
                    varient="bodyl"
                    weightVarient="regular"
                    color={theme.colors.orange[100]}
                  >
                    Learn more about the gamified experience in Novatr's live projects.
                  </Typography>
                  <Link
                    href="https://www.archdaily.com/984231/how-gamification-can-transform-architecture-education?ad_medium=gallery"
                    target={"_blank"}
                  >
                    <Typography
                      varient="bodyl"
                      weightVarient="semibold"
                      color={theme.colors.gray[200]}
                      style={{ cursor: "pointer" }}
                    >
                      Click to learn more
                    </Typography>
                  </Link>
                </FooterStyle>
              </GridColumn>
            </PracticalTrainingCard>
          </Box>
        </GridContainer>
      </Container>
      <FooterStyleMobile>
        <Typography varient="bodyl" weightVarient="regular" color={theme.colors.orange[100]}>
          Learn more about the gamified experience in Oneistox's live projects.
        </Typography>
        <Link
          className="link__"
          href="https://www.archdaily.com/984231/how-gamification-can-transform-architecture-education?ad_medium=gallery"
        >
          <Typography
            varient="bodyl"
            weightVarient="semibold"
            color={theme.colors.gray[200]}
            style={{ cursor: "pointer" }}
          >
            Click to learn more
          </Typography>
        </Link>
      </FooterStyleMobile>
    </>
  );
};

export default PracticalTraining;
